/*----------------------------------------------------------------------------*\
    $Helpers
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */



/*----------------------------------------------------------------------------*\
    $FUNCTIONS
\*----------------------------------------------------------------------------*/

@function z-index($keys) {
    $map: $z-indexes;
    $found-index: null;

    @each $key in $keys {
        @if (type-of($map) == "map") {
            $found-index: index(map-keys($map), $key);
            $map: map-get($map, $key);
        }
        @else {
            $found-index: index($map, $key);
        }
    }

    @return $found-index;
}

@function z($keys...) {
    @return z-index($keys);
}

.fill--blank,
.fill--white {
    fill: #FFFFFF;
}

.fill--brand-sec {
    fill: color(brand-sec);
}

.color--blank,
.color--white {
    color: #FFFFFF;
}

.lines--3{@include rem(height, $space*3);}
.position--relative{
    position: relative !important;
}

.icon-link{
    text-decoration: none;
    color: color(base);
    font-weight: 300;
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    svg{
        fill: color(brand-sec);
        height: 20px;
        width: 20px;
        display: inline-block;
        vertical-align: bottom;
    }
    img{
        height: $space;
        width: $space;
    }

    &.icon-link--brand{
        svg{
            fill: color(brand);
        }
    }
}

.nowrap{
    white-space: nowrap;
}

.site-width--cta{
    position: relative;

    .cta-person{
        position: absolute;
        right: $space;
        bottom: $space*-2;
    }
}

#map{
    height: 300px;
    width: 100%;
}

.car-details{
    display: inline-block;
    font-size: 0;
    text-align: left;
    .car-details__group{
        display: inline-block;
        font-size: $base-font-size;
        margin-right: $space;
        dt{
            color: color(base);
            text-align: left;
        }
        dd{
            font-weight: 600;
            color: color(brand);
            text-align: left;
            margin: 0;
        }
    }
}

.speclist{
    font-size: 0;
    dt{
        color: color(base);
        text-align: left;
        display: inline-block;
        width: 30%;
        font-size: $base-font-size;
        border-bottom: 1px solid color(base-med);
        padding: $space-half $space-half $space-half 0;
    }
    dd{
        font-weight: 600;
        color: color(brand);
        text-align: left;
        margin: 0;
        display: inline-block;
        width: 70%;
        font-size: $base-font-size;
        border-bottom: 1px solid color(base-med);
        padding: $space-half;
    }
}
.speclist--options{
    font-size: 0;
    dt{
        color: color(base);
        text-align: left;
        display: inline-block;
        width: 100%;
        font-size: $base-font-size;
        border-bottom: 1px solid color(base-med);
        padding: $space-half $space-half $space-half 0;
    }
    dd{
        display: none;
    }
}

.stats{
    .stats__item{
        height: $space*10;
        background-size: cover;
        background-position: center center;
        position: relative;
        padding: $space*3 0 0 0;

        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.6);
        }
        .stats__number{
            position: relative;
            z-index: 1;
            text-align: center;
            color: color(brand-sec);
            font-weight: 700;
            font-size: $alpha;
        }
        .stats__description{
            position: relative;
            z-index: 1;
            text-align: center;
            color: color(blank);
            font-weight: 600;
        }
    }
}

.gallery{
    display: block;
    min-height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: $space-half;

    &.gallery--pri{
        height: 500px;
    }
    &.gallery--sec{
        height: 238px;
    }
    &.gallery--ter{
        height: 119px;
    }

    &.gallery--trigger{
        padding: $space;
        text-align: center;
        text-decoration: none;
        background-color: color(brand);
        color: rgba(color(blank), 0.9);
        font-size: $delta;

        div{
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@mixin hidden {
    display: none !important;
    visibility: hidden;
}

.hidden{
    @include hidden;
}

@each $state in $breakpoint-has-visuallyhidden {
    @include media-query(#{$state}) {
        .hidden--#{$state} {
            @include hidden;
        }
    }
}

/*---------- MEDIA QUERIES ----------*/
@include media-query('lap'){
    .speclist{
        font-size: 0;
        dt{
            color: color(base);
            text-align: left;
            display: inline-block;
            width: 40%;
            font-size: $base-font-size;
            border-bottom: 1px solid color(base-med);
            padding: $space-half $space-half $space-half 0;
        }
        dd{
            font-weight: 600;
            color: color(brand);
            text-align: left;
            margin: 0;
            display: inline-block;
            width: 60%;
            font-size: $base-font-size;
            border-bottom: 1px solid color(base-med);
            padding: $space-half;
        }
    }
}
@include media-query('palm'){
    .speclist{
        font-size: 0;
        dt{
            color: color(base);
            text-align: left;
            display: inline-block;
            width: 45%;
            font-size: $base-font-size;
            border-bottom: 1px solid color(base-med);
            padding: $space-half $space-half $space-half 0;
        }
        dd{
            font-weight: 600;
            color: color(brand);
            text-align: left;
            margin: 0;
            display: inline-block;
            width: 55%;
            font-size: $base-font-size;
            border-bottom: 1px solid color(base-med);
            padding: $space-half;
        }
    }
}

.sign-inv {
    margin-bottom: -$space;
}

ul.checklist,
ul.excllist {
	list-style: none;
	margin: 0 0 $space-half 0;
	padding: 0;
	overflow: hidden;

	li {
		padding-left: $space*1.5;
		margin-bottom: $space-half;
		background-size: $space $space;
		background-position: left top;
		background-repeat: no-repeat;
	}
}

ul.checklist li {
	background-image: url('../gfx/check.svg?v=1');
}

ul.excllist li {
	background-image: url('../gfx/cross.svg');
}