@media only screen and (min-width: 961px) {
  .unit-3-12--desk {
    width: 25%!important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 960px) {
  .unit-6-12--lap {
    width: 50%!important;
  }
}

.unit-1-1 {
  width: 100%;
}

.card.js-card {
  display: block!important;
  height: auto;
  border-bottom: 0;

  .card__visual {
    margin-bottom: 15px;

    figure {
      margin: 0;
    }

    .visual img {
      max-width: 100%;
      height: auto;
      width: 100%;

      @media only screen and (min-width: 481px) and (max-width: 960px) {
        width: 100%;
      }
    }
  }

  .card__main {
    position: relative;

    .card__heading {
      font-size: 1.125rem;
      margin-bottom: 12px;
      padding-right: 1.6rem;

      .card__title {
        margin-bottom: .6rem;
      }
      .card__subtitle {
        font-size: .9rem;
        font-weight: 600;
        margin-bottom: .6rem;
      }
    }

    .card__content {
      padding: 0;
    }
  }

  .card__actions {
    display: flex;
    flex-direction: column;

    a.button-arrow {
      background-color: #94c01f;
      box-shadow: 0 3px 0 0 #587900;
      border: 0;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      height: auto;
      margin-bottom: 24px;
      padding: 6px 24px;
      cursor: pointer;
      display: inline-block;
      line-height: 2;
      text-decoration: none;
    }

    vm-button {
      position: absolute;
      content: "";
      top: calc(50vw - 5px);
      right: 0;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23702382' d='M0 11h11.6l-2.3 2.3 1.4 1.4 4.7-4.7-4.7-4.7-1.4 1.4L11.6 9H0z'/%3E%3Cpath fill='%23702382' d='M2.6 7a8 8 0 1 1 0 6H.5a10 10 0 1 0 0-6h2z'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      text-indent: -9999px;

      @media only screen and (min-width: 961px) {
        top: 274px;
      }

      @media only screen and (min-width: 481px) and (max-width: 960px) {
        top: calc(50vw - 20px);
      }
    }
  }

}

.related-container .layout__cell > a {
  display: none;
}

.related-container .heading {
  color: #363636;
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
}

.related-container .heading-s {
  margin-bottom: 6px;
}
