/*----------------------------------------------------------------------------*\
    Main
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

/*
CONTENTS................You're reading it, LOL
MODULE..................Includes base module & submodules
STATE...................States the module can be in
THEME...................Theming of the module
*/





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/
.mainnav__container{
    //display: flex;
    padding-left: $space-half;
    //align-items: center;
    //white-space: nowrap;
    position: relative;

    .mainnav__more{
        display: inline-block;
        padding: 0;
        padding-left: $space;
        text-decoration: none;
        font-weight: 600;
        color: #FFF;
        position: relative;
        top: 5px;
        font-size: 0;

        span{
            font-size: $base-font-size;
        }

        svg{
            height: 10px;
            width: 16px;
            transform: rotate(90deg);
            fill: #FFF;
            transition: transform 200ms $transition;
        }

        &[data-state='active']{
            svg{
                transform: rotate(-90deg);
            }
        }

        &::after{
            content: attr(count);
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            background: color(blank);
            color: color(brand-sec);
            border-radius: 50%;
            font-size: $micro;
            font-weight: 600;
            top: -10px;
            right: -10px;
            text-align: center;
            line-height: 15px;
            border: 2px solid color(brand-sec)
        }
    }
}
.mainnav{
    margin: 0;
    padding: 0;
    list-style-type: none;
    //flex: 1;
    //overflow: hidden;
    display: inline-block;
    font-size: 0;

    .mainnav__item{
        display: inline-block;
        line-height: $space;
        vertical-align: middle;

        .mainnav__link{
            display: inline-block;
            padding: 0 $space-half 0;
            text-decoration: none;
            font-weight: 600;
            color: color(base);
            transition: color 200ms $transition;
            font-size: $base-font-size;
        }
    }
}

.mainnav--hidden{
    //display: none;
    opacity: 0;
    position: absolute;
    right: $space-half;
    top: 50%;
    background-color: #FFF;

    list-style-type: none;

    transition: top 200ms $transition, opacity 200ms $transition;
    height: 0;
    overflow: hidden;

    .mainnav__item{

        svg:hover{
            fill: color(brand);
        }
        .mainnav__link{
            display: block;
            padding: $space-half/2 0;
            text-decoration: none;
            font-weight: 600;
            color: color(base);
            transition: color 200ms $transition;

            &:hover{
                color: color(brand-sec);
            }

            &.mainnav__link--active{
                color: color(brand);
            }
        }
    }

    &[data-state='active']{
        //display: block;
        opacity: 1;
        top: 110%;
        height: auto;
        padding: $space $space*1.5;
        box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);
    }
}


/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/