/*----------------------------------------------------------------------------*\
    quicknav
\*----------------------------------------------------------------------------*/

.quicknav{}

.quicknav__title{
    background: color(brand);
    color: color(blank);
    padding: $space-half;
    font-weight: 700;
}
.quicknav__content{
    padding: $space-half;
    background: color(subtle);
    border-bottom: 2px solid color(base-med);
    margin-bottom: $space;
}

.quicknav__list{
    margin: 0;
    padding: 0;
    list-style-type: none;

    .quicknav__list-item{
        margin:0;
        padding: 0;
        display: block;
    }
}

.quicknav__link{
    color: color(base);
    display: block;
    text-decoration: none;
    margin-bottom: $space-half;
    transition: transform 100ms $transition, color 200ms $transition;

    svg{
        width: 16px;
        height: 10px;
        margin-right: $space-half;
    }

    &:hover{
        transform: translateX($space-half);
        color: color(brand);

        svg{
            fill: color(brand);
        }
    }
}

/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}

