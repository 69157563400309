/*----------------------------------------------------------------------------*\
    DROPDOWN MAIN MENU
    .dropdownnav, .dropdownnav__item, .dropdownnav__link
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.mainnav__item {
    position: relative;
}

.dropdownnav {
    display: none;
    list-style: none;
    position: absolute;
    background-color: color(blank);
    padding: $space/2;
    top: 68px;
    left: 0;
    margin: 0;
    box-shadow: 0px 4px 5px rgba(0,0,0,0.3);
}

.mainnav__item:hover > .dropdownnav {
    display: block;
}

.dropdownnav__item {
    @include font-size($base-font-size);
    &:not(:last-child) {
        margin-bottom: $space/2;
    }
}

.dropdownnav__link {
    @include font-size($base-font-size);
    text-decoration: none;
    color: color(base);
    white-space: nowrap;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #C1C1C1;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s ease;
        transition-timing-function: ease-in;
    }

    &:hover,
    &.dropdownnav__link--current,
    &.dropdownnav__link--section {
        color: color(base-dark);

        &::after {
            transform: scaleX(1);
            transform-origin: left;
            transition-timing-function: ease-out;
        }
    }
}