/*----------------------------------------------------------------------------*\
    Parent
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

/*
CONTENTS................You're reading it, LOL
MODULE..................Includes base module & submodules
STATE...................States the module can be in
THEME...................Theming of the module
*/





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/
.parentnav__container{
    //display: flex;
    padding-left: $space;
    //align-items: center;
    //white-space: nowrap;
    position: relative;

    .parentnav__title{
        font-weight: 600;
        color: #FFF;
        font-size: $delta;
        text-decoration: none;

        svg{
            height: 10px;
            width: 16px;
            fill: #FFF;
            transform: rotate(0deg);
            margin-left: $space-half;
            transition: transform 200ms $transition;
        }

        &[data-state='active']{
            svg{
                transform: rotate(180deg);
            }
        }

        &[data-state='expanded']{
            display: none;
        }
    }
}
.parentnav{
    position: absolute;
    opacity: 0;
    left: $space-half;
    top: 50%;
    z-index: 10;
    font-size: 0;

    list-style-type: none;

    transition: top 200ms $transition, opacity 200ms $transition;
    height: 0;
    overflow: hidden;

    &[data-state='active']{
        //display: block;
        opacity: 1;
        top: 110%;
        height: auto;
        padding: $space-half $space*1.5;
        box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);
        background-color: #FFF;

        .parentnav__link {
            padding: 8px 0 !important;
        }
    }

    &[data-state='expanded']{
        opacity: 1;
        display: block;
        position: relative;
        margin: 0;
        height: auto;
        top: auto;
        left: auto;

        .parentnav__item{
            display: inline-block;

            &:not(:last-child) {
                margin-right: $space;
            }

            .parentnav__link{
                color: #FFF;
            }
        }
    }

    .parentnav__item{
        display: block;

        .icon-link a:hover{
            svg{
                fill: color(brand);
            }
        }

        .parentnav__link{
            display: block;
            text-decoration: none;
            color: color(base);
            font-weight: 600;
            padding: $space 0;
            position: relative;
            @include font-size(16px);

            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 4px;
                width: 100%;
                background-color: #FFF;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform .2s ease;
                transition-timing-function: ease-out;
            }

            &:hover::after,
            &.parentnav__link--current::after,
            &.parentnav__link--section::after {
                transform: scaleX(1);
                transform-origin: left;
                transition-timing-function: ease-in;
            }
        }
    }
}
.mainnav{
    margin: 0;
    padding: 0;
    list-style-type: none;
    //flex: 1;
    //overflow: hidden;
    display: inline-block;
    font-size: 0;

    .mainnav__item{
        display: inline-block;

        &:not(:last-child) {
            margin-right: $space;
        }

        .mainnav__link{
            display: inline-block;
            padding: 22px 0;
            text-decoration: none;
            font-weight: 600;
            color: #FFFFFF;
            position: relative;
            @include font-size(16px);

            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 4px;
                width: 100%;
                background-color: #FFF;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform .2s ease;
                transition-timing-function: ease-in;
            }
        }

        &:hover .mainnav__link::after,
        > .mainnav__link--current::after,
        > .mainnav__link--section::after {
            transform: scaleX(1);
            transform-origin: left;
            transition-timing-function: ease-out;
        }
    }
}

.mainnav--hidden{
    //display: none;
    opacity: 0;
    position: absolute;
    right: $space-half;
    top: 50%;

    list-style-type: none;

    transition: top 200ms $transition, opacity 200ms $transition;
    height: 0;
    overflow: hidden;

    .mainnav__item{
        .mainnav__link{
            display: block;
            padding: $space-half/2 0;
        }
    }

    &[data-state='active']{
        //display: block;
        opacity: 1;
        top: 110%;
        height: auto;
        padding: $space $space*1.5;
        box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);
    }
}

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

/* Media Queries
   The default media queries shipped with Blocss:
   Uncomment the ones you need
\*------------------------------------------------------------------------*/

/**
 * Desk-wide state
 */
//@include media-query(gt-desk){}


/**
 * Desk state
 */
//@include media-query(desk){}


/**
 * Portable state
 */
//@include media-query(lt-desk){}


/**
 * Lap state
 */
//@include media-query(lap){}


/**
 * Lapup state
 */
//@include media-query(gt-palm){}


/**
 * Palm state
 */
//@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/