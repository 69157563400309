/*----------------------------------------------------------------------------*\
    IMAGE EFFECT
    .image-effect
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.image-effect {
    margin: 0;
}

.image-effect__image {
    display: block;
}

.image-effect--zoom {
    overflow: hidden;
    background-color: #000;

    .image-effect__image {
        opacity: 1;
        transform: scale(1);

        transition:
            opacity .2s ease-out,
            transform .2s ease-out;
    }

    &:hover {
        .image-effect__image {
            opacity: 0.85;
            transform: scale(1.05);
        }
    }
}

