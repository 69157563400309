/*----------------------------------------------------------------------------*\
    card
\*----------------------------------------------------------------------------*/

.contact__opening-hours{
    display: inline-block;
    color: color(brand);
}
dl.contact__opening-hours-wrapper{
    display: inline-block;
    margin-left: $space-half/2;
}

/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}

