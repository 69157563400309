/*----------------------------------------------------------------------------*\
    FAQ
\*----------------------------------------------------------------------------*/

.category-wrapper{
    background: color(subtle);
    padding: $space*2 0 0 $space*2;
    border-bottom: 2px solid color(brand);
    position: relative;

    &:hover{
        cursor: pointer;
    }
    &__children{
        display: none;

        ul{
            li{
                list-style: none;
            }
        }

    }
    &.is--active{
        .category-wrapper__children{
            padding-bottom: $space;
            display: inline-block;
        }
        .category__icon{
            transform: rotate(180deg);
        }
        .category__title{
            padding-bottom: $space*1.5;
        }

    }

    .category__title{
        font-size: $gamma;
        font-weight: 400;
        padding-bottom: $space*2;
    }
    .category-wrapper__children{
        padding-left: $space-half;
        
        a{
            text-decoration: none;
            color: color(brand);
            font-weight: 600;
        }
    }
}
    .category__icon{
        position: absolute;
        right: $space;
        display: block;
        font-size: 0;
        top: $space*2.5;
        transition: transform ease-in-out .2s;
    }


/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        @include media-query(lap){
            .category-wrapper{
                padding: $space*2 $space-half 0 $space-half;

                ul{
                    @include rem(margin-left, $space-half);
                }
            }
        }


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .category-wrapper{
                padding: $space*2 $space-half 0 $space-half;

                ul{
                    @include rem(margin-left, $space-half);
                }
            }
        }

