/*----------------------------------------------------------------------------*\
    language
\*----------------------------------------------------------------------------*/
.languageswitch{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    .flag{
        display: block;
        background: color(subtle);
        padding: 22px 8px 22px 19px;
        text-decoration: none;
        svg.arrow{
            display: inline-block;
            width: 12px;
            height: 8px;
            margin-left: $space-half/2;
            fill: color(base-med);
        }
        svg{
            display: inline-block;
            width: 22px;
            height: 16px;
            vertical-align: middle;
        }
    }
    .languagenav {
        position: absolute;
        z-index: 1;
        background: color(subtle);
        //display: none;
        margin: 0;
        padding: 0;
        list-style-type: none;
        box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.1), -2px 3px 4px 0px rgba(0, 0, 0, 0.1);
        display: none;

        .languagenav__item{

            .languagenav__link{
                padding: 22px 19px;
                &:hover{
                    background: lighten(color(base-med), 30);
                }
            }
        }
    }

    &:hover{
        .languagenav{
            display: block;
        }
    }
}


/*----------------------------------------------------------------------------*\

\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}



