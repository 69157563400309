/*----------------------------------------------------------------------------*\
    BUTTON
\*----------------------------------------------------------------------------*/

.button{ // WERKPLAATS PLANNER MODAL
    font-weight: 600;
    color: color(blank) !important;
    font-size: $milli;
    border: 0;
    padding: $space-half/2 $space;
    height: auto !important;
}

.button--large{
    padding: $space-half $space;
}

.button--link{
    color: color(brand-sec);
    display: block;
    text-decoration: none;
    font-weight: 400;
    font-size: $gamma;
}

.button--brand-sec{
    background-color: color(brand-sec);
    box-shadow: 0 3px 0 0px saturate(darken(color(brand-sec), 20), 30);
    transform: translateY(0px);
    transition: transform 100ms $transition, background-color 100ms $transition, box-shadow 100ms $transition;

    &:hover{
        background-color: saturate(darken(color(brand-sec), 5), 15);
        box-shadow: 0 2px 0 0px saturate(darken(color(brand-sec), 25), 40);
        transform: translateY(1px);
    }
}


// BUTTONS OVEGENOMEN VAN VAN MOSSEL

.btn{
    @extend .button;
    @extend .button--brand-sec;
}

/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}

