.modal{
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;

	z-index: 99999;

	.img--full,
	iframe{
		display: block;
	}
}

.modal__overlay{
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.6);
	z-index: 1;
}

.modal__box{
	display: block;
	max-width: 700px;
	width: 90%;
	/* min-height: 300px; */
	max-height: 70%;
	background-color: #FFF;
	
	position: absolute;
	top: 150px;
	left: 50%;

	transform: translateX(-50%);

	z-index: 2;

	overflow: auto;
}

.modal__close{
	cursor: pointer;

	svg{
		pointer-events: none;
		display: none; /* JENT ONLY */
	}
}

.modal__close--topright{
	position: absolute;
	top: $space-half;
	right: $space-half;
	z-index: 999;

	@include rem(width, $space*1.5);
	@include rem(height, $space*1.5);
	background-image: url('../gfx/close.svg');
	background-size: $space*1.5 $space*1.5;
	background-repeat: no-repeat;
}

@include media-query(palm){
	.modal__box{
		max-height: 80%;
		top: $space;

		.continent{
			padding: $space*2 $space-half $space-half;
		
			h2.kilo{
				@include font-size($beta);
			}
		}
	}
}
