/*----------------------------------------------------------------------------*\
    Layout
    .layout

\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/


$dimensions-breakpoint-has-fit: () !default;
$dimensions-breakpoint-has-fill: ('desk') !default;

/*  Mixins
\*----------------------------------------------------------------------------*/

@mixin layout-fit () {
    flex-basis: auto;
}

@mixin layout-fill () {
    flex: 1 1 0%; /* [1] */
    flex-basis: 0%; /* [2] */
}

/*  Component
\*----------------------------------------------------------------------------*/



.layout {
    display: flex; /* [1] */
    flex-flow: row wrap;
    padding: 0; /* [1] */
    margin-left: 0; /* [1] */
    list-style: none; /* [1] */
}

// MODIFIER: ALIGN CENTER
.layout--center {
    justify-content: center;
}

// MODIFIER: ALIGN RIGHT
.layout--right {
    justify-content: flex-end;
}

.layout--spread {
    justify-content: space-between;
}

// MODFIER: ALIGN MIDDLE VERTICALLY
.layout--middle {
    align-items: center;
}

// MODIFIER: REVERSE DIRECTION
.layout--rev {
    flex-direction: row-reverse;
}

// MODIFIER: ALIGN BOTTOM VERTICALLY
.layout--bottom {
    align-items: flex-end;
}

// MODIFIER: EQUAL DISTRIBUTE WIDTH
.layout--fit {
    > .layout__cell {
        @include layout-fit;
    }
}

// MODIFIER: ALL CELLS MATCH HEIGHT OF TALLEST IN A ROW
.layout--equalheight {
    > .layout__cell {
        display: flex;

        > * {
            width: 100%; /* [1] */
        }
    }
}

// MODIFIER: GUTTER
.layout--gutter {
    @include rem(margin-left, -$space);

    > .layout__cell {
        @include rem(padding-left, $space);
    }
}

// MODIFIER: TINY GUTTER
.layout--gutter-xs {
    @include rem(margin-left, -$space/3);

    > .layout__cell {
        @include rem(padding-left, $space/3);
    }
}

// MODIFIER: SMALL GUTTER
.layout--gutter-s {
    @include rem(margin-left, -$space/2);

    > .layout__cell {
        @include rem(padding-left, $space/2);
    }
}

// MODIFIER: LARGE GUTTER
.layout--gutter-l {
    @include rem(margin-left, -$space*1.25);

    > .layout__cell {
        @include rem(padding-left, $space*1.25);
    }
}

// MODIFIER: HUGE GUTTER
.layout--gutter-xl {
    @include rem(margin-left, -$space*1.5);

    > .layout__cell {
        @include rem(padding-left, $space*1.5);
    }
}

.layout__cell {
    flex-basis: 100%; /* [1] */
    min-width: 0; /* [2] */
}

.layout__cell--center {
    margin-left: auto;
    margin-right: auto;
}

// MODIFIER: SHRINK WRAP ITS CONTENT
.layout__cell--fit {
    @include layout-fit;
}

// MODIFIER: FILL OPEN SPACE
.layout__cell--fill {
    @include layout-fill;
}


// RESPONSIVE
@each $name in $dimensions-breakpoint-has-fit {
    @include media-query($name) {
        .layout__cell--fit--#{$name} {
            @include layout-fit;
        }
    }
}

@each $name in $dimensions-breakpoint-has-fill {
    @include media-query($name) {
        .layout__cell--fill--#{$name} {
            @include layout-fill;
        }
    }
}