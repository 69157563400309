@charset "UTF-8";
/*----------------------------------------------------------------------------*\
        theme.scss
        Theme stylesheet, extends Blocss CSS Framework
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
        CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    README
    INCLUDES............Include various sass files
    BASE................High-level elements like 'html', 'body', etc.
    LAYOUT..............Structural styling
    MODULES.............Reusable, modular parts of the page
    */




/*----------------------------------------------------------------------------*\
    $INCLUDES
    Include various sass files
\*----------------------------------------------------------------------------*/

    /**
     * If you use compass enable this
     */
    @import "vars";
    @import "../lib/blocss/blocss";
    @import "../lib/blocss-arrange/arrange";
    @import "../lib/blocss-buttons/buttons";
    @import "../lib/blocss-flexembed/flexembed";
    @import "../lib/blocss-grid/grid";
    @import "../lib/blocss-dimensions/dimensions";
    @import "../lib/blocss-island/island";
    @import "../lib/blocss-media/media";
    @import "../lib/blocss-navigation/navigation";
    @import "../lib/blocss-helpers/helpers";





    /**
     * Theme includes
     */
    @import "theme/base";
    @import "theme/layout";
    @import "theme/modules";
    @import "theme/nav";
    @import "theme/plugin";