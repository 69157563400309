/*----------------------------------------------------------------------------*\
    ICONSLIST
    .iconlist
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.iconlist {
	list-style: none;
	margin: 0;
	padding: 0;
	@include font-size($delta);

	li {
		margin-bottom: $space-half;
	}
}

.iconlist--privelease {
	height: $space*7.5;
}

.iconlist__icon {
	float: left;
	margin-right: $space-half;
	width: 32px;
	height: 32px;
}

.iconlist__label {
	line-height: 32px;
}

.iconlist--l {

	.iconlist__icon {
		@include rem(width, $space*1.5);
		@include rem(height, $space*1.5);
		@include rem(margin-right, $space*0.75);
	}

	.iconlist__label {
		@include font-size(16px, 1.5);
		font-weight: 600;
	}
}

.iconlist--s {

	.iconlist__icon {
		width: $space;
		height: $space;
		margin-right: $space/2;
	}

	.iconlist__label {
		@include font-size($base-font-size);
	}
}

.iconlist--inline {

	li,
	.iconlist__icon,
	.iconlist__label {
		display: inline-block;
	}

	li {
		margin-left: $space-half;
		margin-right: $space-half;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.iconlist--l.iconlist--inline {
	li {
		margin-left: 0;
		margin-right: $space*2;

		&:last-child {
			margin-right: 0;
		}
	}
}