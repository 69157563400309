.multiple__slider,
.carousel{
	padding: 0 $space*3.5;

	a:focus{outline: none;}
}

.multiple__slider--gutter .slick-slide{
	margin: 0 $space-half;
}

.single__slider{
	padding: 0 $space*5;
}

.slider__arrow{
	width: $space*3;
	height: $space*3;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: all .3s ease-in-out;
    cursor: pointer;
    fill: color(brand-sec);

	&.slick-disabled{
		fill: color(subtle);
		cursor: default;
	}
}

.slider__arrow--next{
	right: 0;
}

.slider__arrow--prev{
	left: 0;
}

.slick-dotted > ul.slick-dots{
	list-style: none;
	padding: $space-half 0 0;
	margin: 0;
	text-align: center;

	> li{
		font-size: 0;
		display: inline-block;
		margin: 0 $space*0.1;

		> button{
			display: block;
			width: $space-half;
			height: $space-half;
			background-color: color(base-med);
			border-radius: $space-half/2;
			transition: background-color .3s ease-in-out;
			outline: none;
			border: 0;
			padding: 0;
			margin: 0;
		}

		&.slick-active > button{
			background-color: color(brand-sec);
		}
	}
}

@include media-query(palm){
	.multiple__slider{
		padding: 0;
	}

	.single__slider{
		padding: 0 $space*1;
	}

	.multiple__slider__item{
		text-align: center;
		margin: 0 auto;

		a,
		> img {
			display: inline-block;
		}
	}

	.multiple__slider--gutter .multiple__slider__item{
		margin: 0;
	}

	.slider__arrow{
		width: $space;
		height: $space;
		z-index: 999999;
	}

	.slider__arrow--next{
		right: 0;
	}

	.slider__arrow--prev{
		left: 0;
	}
}
