/*----------------------------------------------------------------------------*\
    $TOPLEVEL
    html, body, .wrapper
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    /* commented backslash hack \*/
    html, body { height: 100%; }
    /* end hack */
    * html .wrapper { height: 100%; }

    body{
        position: relative;
        min-height: 100%;
        -webkit-font-smoothing: antialiased;
        color: color(base);
        font-weight: 400;
    }


    /**
     * Make wrapper 100% height
     */
    .wrapper {
        position: relative;
        min-height: 100%;
        height: auto;
        width: 100%;
        margin: 0 auto;
    }

        .site-width{
             margin: 0 auto;
             @include rem(max-width, $site-width);
             @include rem(padding, 0 $space);

            &--extend{
                @include rem(max-width, $site-width+$space*8);
            }

            &--extend-xl{
                @include rem(max-width, $site-width+$space*16);
            }
         }

    *[data-role="toggler"]{
        cursor: pointer;
    }

    *[data-role="togglee"]{
        display: none;

        &[data-state="active"]{
            display: block;
        }
    }

    .toggle-content {
        display: none;
    }

    *[data-role="toggler"], *[data-role="togglee"]{
        &[data-state="active"] > .toggle-content--active {
            display: block;
        }
        &[data-state="passive"] > .toggle-content--passive {
            display: block;
        }
    }


@include media-query(palm){
    body{
        padding-top: $space*2;
    }
}

