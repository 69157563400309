/*----------------------------------------------------------------------------*\
    $BLOCK
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */



/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    .block{
        padding: $space-half;
        background: color(subtle);
        border-bottom: 2px solid color(base-med);
        margin-bottom: $space;

    }

    .block__title{
        color: color(brand);
        font-size: $delta;
        margin-bottom: $space-half;
        font-weight: 600;
    }

    .block__content{
        ul{
            font-weight: 300;

            li{
                color: color(base);
            }
        }
    }
    .block--language{
        display: block;
        padding: $space-half $space;
        background: color(brand);
        border-bottom: 2px solid color(base);
        margin-bottom: $space;
        white-space: nowrap;
        text-decoration: none;
        &:hover{
            background: darken(color(brand), 10%);
        }

        .svg--language{
            height: $space*1.5;
            width: $space*1.5;
            vertical-align: middle;
        }
    }

    .blockquote{
        padding: $space*1.5 $space*3;
        background: url(../gfx/icons/quotes.svg) no-repeat bottom $space left $space;
        background-color: color(subtle);
        border-bottom: 2px solid color(base-med);
        margin-bottom: $space;

        .blockquote__content{
            font-weight: 600;
            margin-bottom: $space-half;
        }
        .blockquote__sub{
            font-weight: 400;
            color: color(brand);
        }
    }

.block--direct{
    padding: 0;

    .block--direct__image{
        img{
            display: block;
            width: 100%;
            height: auto;
        }

        .block--direct__image-placeholder{
            width: 100%;
            height: 170px;
            background-color: lighten(color(subtle), 2%);
        }

    }
    &--help{
        margin-top: -$space*4;
    }

    .block--direct__content{
        padding: $space $space*1.5 $space-half $space-half;
        background: color(subtle);
        border-bottom: 2px solid color(base-med);
        font-size: $milli;
    }

    .block--direct__link{
        display: block;
        color: color(blank);
        font-weight: 600;
        background-color: color(brand);

        text-decoration: none;
        text-align: center;
        padding: $space-half;
        box-shadow: 0 3px 0 0px saturate(darken(color(brand), 15), 30);
        transform: translateY(0px);
        transition: transform 100ms $transition, background-color 100ms $transition, box-shadow 100ms $transition;

        &:hover{
            background-color: saturate(darken(color(brand), 5), 15);
            box-shadow: 0 2px 0 0px saturate(darken(color(brand), 25), 40);
            transform: translateY(1px);
        }
    }

    .block--direct__title{
        color: color(brand);
        margin-bottom: $space-half;
        font-size: $delta;
    }

    .block--direct__subtitle{
        margin-bottom: $space-half;
        font-weight: 600;
    }

    .block--direct__icon-links{
        @include rem(height, 64px); /* fixed line-heigt 20px + spaces */
    }
}

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/