/*----------------------------------------------------------------------------*\
    $FOOTER
    bottom of page
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/
.subfooter{
    padding: 0 $space;
    font-weight: 300;
    color: color(base-med);

    a{
        text-decoration: none;
    }
}
.footer-appstore{
    // padding-top: $space;
    img{
        width: 130px;
    }
}

/* Styling for CTA section 'Direct hulp' */
.direct-help{
    background-size: cover;
    background-position: center center;
    position: relative;

    &:before{
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        top: 0;
        left: 0;
    }

    &__contact{
        position: relative;
        z-index: 2;
        &__title{
            font-weight: 600;
            @include rem(font-size, $delta);
            color: color(blank);
        }
        .icon-link{
            display: block;
            font-weight: 600;
        }
    }
}



/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        @include media-query(lap){
            .direct-help{
                text-align: center;

                &__contact{
                    margin-bottom: $space;
                    h1, .subtitle{
                        text-align: center;
                    }
                }
            }
            /* Styling for Contact section 'Contact'*/
            .contact__title{

            }
        }


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .direct-help{
                text-align: center;

                &__contact{
                    margin-bottom: $space;
                    h1, .subtitle{
                        text-align: center;
                    }
                }
            }
            /* Styling for Contact section 'Contact'*/
            .contact__title{
                text-align: center;
            }
        }

/** mostly copied from vm-theme */
.footer-nav {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding: 0;
    margin-left: -2.4rem;
    margin-bottom: 2.4rem;

    > div {
        /* palm **/
        @media only screen and (max-width: 767px) {
            width: 50%;
        }

        width: 25%;
        padding-left: 2.4rem;
    }

    .icon-link {
        display: flex;
        gap: 0.8rem;
        align-items: center;
    }
}




/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
