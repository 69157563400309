/*----------------------------------------------------------------------------*\
    HERO
\*----------------------------------------------------------------------------*/

.layer--hero{
    position: relative;
    margin-bottom: $space*-2;


    .hero__slide{
        background: url(../gfx/hero-bg.jpg);
        background-size: cover;
        background-position: center center;
        padding: 20vh 0 25vh;
    }
    .hero__slide__content{
        height: 303px;
    }
    .hero__title{
        color: color(blank);
        font-size: $alpha;
        font-weight: 300;
        text-shadow: 0 0 4px rgba(0,0,0, 0.5);
        width: 70%;
        margin-bottom: $space*2;

        strong{
            font-weight: 700;
        }
    }
}

.direct-contact{
    margin-bottom: $space*2;

    .hero__directcontent{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 $space*2;

        > * {
            margin-bottom: 0px;
        }

        > *:not(:last-child) {
            margin-right: $space;
        }

        @include media-query(palm){
            flex-direction: column;
            padding: 0;

            > * {
                margin-bottom: $space;
            }
        }
    }

    .hero__directlink{
        display: block;
        color: color(blank);
        font-weight: 600;
        background-color: color(brand);
        text-decoration: none;
        text-align: center;
        padding: $space-half;
        box-shadow: 0 3px 0 0px saturate(darken(color(brand), 15), 30);
        transform: translateY(0px);
        transition: transform 100ms $transition, background-color 100ms $transition, box-shadow 100ms $transition;

        &:hover{
            background-color: saturate(darken(color(brand), 5), 15);
            box-shadow: 0 2px 0 0px saturate(darken(color(brand), 25), 40);
            transform: translateY(1px);
        }
    }

    .hero__directtitle{
        color: color(base);
        font-size: $delta;
    }
}


/*----------------------------------------------------------------------------*\

\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        @include media-query(lap){
            .hero__direct{
                display: none;
            }

            .layer--hero{

                .hero__slide{
                    padding: $space*2 0 $space*3;
                }

                .hero__slide__content{
                    min-height: 300px;
                }

                .hero__title{
                    @include font-size(22px);
                    font-weight: 300;
                    width: 100%;
                    margin-bottom: $space;
                }
            }
        }


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .hero__direct{
                display: none;
            }
            .layer--hero{

                .hero__slide{
                    padding: $space 0 $space*3;
                }

                .hero__slide__content{
                    min-height: 200px;
                }

                .hero__title{
                    @include font-size(18px);
                    width: 100%;
                    margin-bottom: $space;
                }
            }

        }

