/*----------------------------------------------------------------------------*\
    card
\*----------------------------------------------------------------------------*/

/** card with price, in slider */
.slick-slide {
    .card {
        height: 350px;

        .card__title {
            height: 64px;
        }
    }
}

.card{
    display: block;
    text-decoration: none;
    height: 320px;
    color: color(base);
    background: color(blank);
    border-bottom: 2px solid color(base-med);
    position: relative;

    &.card--magazine{
        height: auto;

        .card__content{
            padding-bottom: $space-half;
        }
        .card__image{
            max-height: 235px;
        }
    }

    &.card--magazine-large{
        .card__image{
            max-height: 330px;
        }
    }

    .card__label{
        position: absolute;
        left: $space;
        color: color(base);
        background: color(blank);
        border-bottom: 2px solid color(base-med);
        padding: $space-half $space*2 $space-half $space;
        color: color(brand);
        font-weight: 600;
        font-size: $delta;
    }

    .card__image{
        max-height: 170px;
        overflow: hidden;
        height: 100%;

        img{
            vertical-align: middle;
            max-width: 100%;
            height: auto;
        }
    }

    .card__content{
        padding: $space;
        font-size: $milli;
        line-height: 1.2em;

        .card__title{
            color: color(brand);
            font-weight: 600;
            font-size: $delta;
            margin-bottom: $space-half;
        }
    }

    .card__intro{
        padding-bottom: $space;
        height: $space*4;
    }

    .card__date{
        font-style: italic;
        color: color(base-med);
        position: absolute;
        bottom: $space-half;
        left: $space;
    }

    .card__continue{
        position: absolute;
        right: $space-half;
        bottom: $space-half;
        height: 20px;
        //width: 20px;
        color: color(brand);
        transform: translateX(0);
        transition: transform 100ms $transition;

        svg{
            display: inline-block;
            vertical-align: middle;
            margin-left: 3px;
            height: 20px;
            width: 20px;
            fill: color(brand);
        }
    }

    &:hover{
        .card__continue{
            transform: translateX(8px);
        }
    }
}
.card__image__logo{
    display: block;
    position: absolute;
    z-index: 2;
    left: $space-half;
    top: $space-half;

    &--detail{
        left: $space;
    }

    img{
        max-width: 100%;
    }
}

.card--image{
    background-position: center center;
    background-size: cover;
    color: color(blank);
    padding: $space;

    .card__title{
        font-size: $alpha;
        font-weight: 700;
    }

    .card__continue{

        svg{

            fill: color(blank);
        }
    }

    @include media-query(lap) {
        height: 0;
        padding: 0 $space 100%;

        &::before {
            content: '';
            display: block;
            height: $space;
        }

        .card__title{
            @include font-size(20px);
        }

    }
}

/*----------------------------------------------------------------------------*\

\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .card--image{
                .card--title{
                    font-size: $kilo
                }
            }
        }

