/*----------------------------------------------------------------------------*\
    cta
\*----------------------------------------------------------------------------*/

.cta{
    background-image: url('../gfx/cta.jpg');
    background-size: cover;
    background-position: center;
    padding: $space*2 $space;
    color: color(blank);
    position: relative;

    .cta__content{
        padding-right: 500px;
    }

    .cta__title{
        font-size: $alpha;
        font-weight: 700;
        margin-bottom: $space-half;
    }
    .cta__text{
        margin-bottom: $space;
    }

    .cta__image{
        position: absolute;
        right: $space*2;
        top: -30px;
    }
}
.cta__form{
    font-size: 0;
    input[type="text"]{
        width: 200px;
        border-radius: 5px;
        font-size: $milli;
        font-weight: 300;
        border: 0;
        padding: $space-half;
        vertical-align: top;
        margin-right: $space-half;
        color: color(base);
    }
}

/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        @include media-query(lap){
            .cta{
                .cta__content{
                    padding: 0;
                    text-align: center;

                }
                .cta__image{
                    position: relative;
                    display: block;
                    margin: 0 auto;
                    right: 0;
                    top: $space;
                    max-width: 80%;

                }
            }
        }


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .cta{
                .cta__content{
                    padding: 0;
                    text-align: center;

                }
                .cta__image{
                    position: relative;
                    display: block;
                    margin: 0 auto;
                    right: 0;
                    top: $space;
                    max-width: 80%;

                }
            }
        }

