.gallery__icon{
	position: absolute;
	bottom: $space-half;
	right: $space-half;
	width: $space*2;
	height: $space*2;
}

.gallery__thumb{
	display: block;
	position: relative;
}

.gallery__count{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0,0,0,0.35);
	color: #FFF;
	text-align: center;
	padding: $space*1.25 $space-half;
    @include font-size($delta);
    font-weight: 600;
}

.slider-gallery__paging{
	> ul{
		list-style: none;
		margin: 0;
		padding:0;

		li{
			display: none;
			&.slick-active{
				display: block;
			}
		}
	}
}
.slider-gallery__prev,
.slider-gallery__next{
	position: absolute;
	top: calc(50% - #{$space});
	background-color: #FFF;
	z-index: 9;
	padding: $space-half/2;
	cursor: pointer;

	> img{
		display: block;
		width: $space;
		height: $space;
	}
}

.slider-gallery__prev{
	left: 0;
}

.slider-gallery__next{
	right: 0;
}


// MODEL SLIDER

.model__slider{
	margin: 0 -$space-half;
	padding-top: $space-half;
}

.model__slider__item{
	margin: 0 $space-half;
	padding-bottom: 3px;
}


/**
* Palm state
*/
@include media-query(palm){
	.slider-gallery__prev,
	.slider-gallery__next{
		display: none !important;
	}
}