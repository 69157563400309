/*----------------------------------------------------------------------------*\
    $LAYER
    page part
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.layer{
    padding: $space*2 0;
}

.layer--bottom-larger {
    padding-bottom: $space*3;
}

.layer--top--small {
    padding: $space*2 0 0 0;
}

.layer--top {
    padding: $space*4 0 0 0;
}

// Helper for fluent layer stacking
.bg--subtle + .layer--top {
    background-color: color(subtle);
}

.layer--ne-ma{
    margin-top: $space*-5;
}

.layer--intro{
    padding-bottom: 0;
}

    .layer--wide{
        padding: $space*4 0;

        &--top{
            padding: $space*6 0;
        }
    }

    .layer--small{
        padding: $space 0;
    }

    .intro__image{
        height: $space*13;
        background-position: center center;
        background-repeat: no-repeat;
    }




/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/