/*----------------------------------------------------------------------------*\
    $HEADER
    top of page
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.header{
    position: relative;
    // box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000;

    background-color: color(brand);

    .logo{
        display: block;
        margin-left: $space;
        padding: $space-half 0;

        img{
            display: block;
            max-height: 40px;
            width: auto;
        }
    }

    .topBar{
        width: 100%;
        background: #581a66;
        padding: $space-half/2 16px;
        display: flex;
        gap: 1rem;
        justify-content: flex-end;

        &__link{
            color: white;
            font-weight: 300;
            font-size: .8rem;
            text-decoration: none;
        }

        &__link--icon{
            display: flex;
            gap: .25rem;
            align-items: center;

            & svg{
                width:15px;
                height:15px;
            }
        }
    }

    .subheader{
        padding: 0 0 0 150px;
        background-color: mix(#FFF, color(brand), 10%);
    }
}

.login{
    display: inline-block;
    vertical-align: middle;
    margin-right: $space-half;
    font-size: $base-font-size;

    .icon-link {
        color: #FFF;
    }
}

.mob-menu__toggler {
    display: none;
    text-decoration: none;
    padding-right: $space-half;
    font-size: 0;
    white-space: nowrap;
}
.mob-menu__menu{
    display: inline-block;
    width: $space;
    height: $space-half*1.5;
    position: relative;
    vertical-align: middle;

}
.mob-menu__label{
    font-size: $milli;
    color: #FFF;
    display: inline-block;
    vertical-align: middle;
    margin-right: $space-half;
}
.mob-menu__menu span{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    height: 4px;
    display: block;
    background: #FFF;
    transform: rotate(0deg);
    transition: all .25s $transition;

    &:nth-child(1){
        top: 0;
    }
    &:nth-child(2){
        top: 7px;
        transition-delay: .1s;
    }
    &:nth-child(3){
        top: 14px;
    }

    &.is--active{
        &:nth-child(1){
            top: 7px;
            transform: rotate(45deg);
            transition-delay: .2s;
        }
        &:nth-child(2){
            top: 7px;
            width: 0;
        }
        &:nth-child(3){
            top: 7px;
            transform: rotate(-45deg);
            transition-delay: .2s;
        }
    }
}
.panel{
    position: fixed;
    width: 100%;
    top: 64px;
	height: calc(100% - 64px);
	background: color(blank);
	color: color(base);
	transform: translateX(-100%);
	transition: transform 0.3s ease;
	z-index: 2;
	padding: $space $space $space*2;
	overflow-y: auto;

	&.is--active{
		transform: translateX(0);
	}
}

.panel {
    ul.languagenav {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        left: -$space/2;

        .languagenav__item {
            display: inline-block;
            @include font-size($base-font-size);

            &:not(:last-child) {
                margin-right: $space/2;
            }
        }

        .languagenav__link {
            display: block;
            width: $space*2;
            height: $space*2;
            padding: $space/2;

            > svg {
                width: $space;
                height: $space;
            }
        }
    }
}

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}

        @media only screen and (max-width: 1261px) {
            .header {
                .mainnav__container,
                .login,
                .languageswitch {
                    display: none !important;
                }

                .mob-menu__toggler{
                    display: block;
                }
            }

            .panel{
                .login{
                    display: block;
                    padding: $space 0;

                    .icon-link {
                        color: color(base);
                    }
                }

                .languagenav{
                    display: block;
                }
            }
        }

        @media only screen and (min-width: 1261px) {
            .panel{
                display: none;
            }
        }


        /**
         * Lap state
         */
        @include media-query(lap){
            .header{
                .logo{
                    margin-left: $space-half;
                   img{
                       max-width: 100px;
                   }
                }
                .subheader{
                    padding: 0 0 0 138px;
                }
            }
        }


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .header{
                position: fixed;
                top: 0;
                background: color(brand);
                .logo{
                    margin-left: $space-half;
                   img{
                       max-width: 100px;
                   }
                }
                .subheader{
                    padding: 0 0 0 138px;
                }
            }
            .login{
                display: none;

            }
        }





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/