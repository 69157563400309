.highlight {
	display: block;
	width: 100%;
	height: $space*9;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;

	&:after{
		display: block;
		content: '';
	}
}

.highlight--double {
	height: $space*18;
}

.highlight--video{
	&:after{
		width: $space*2.5;
		height: $space*2.5;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #000;
		background-image: url(../gfx/icons/plus.svg);
		background-position: center;
		background-size: $space*1.5 $space*1.5;
		background-repeat: no-repeat;
	}
}

.highlight--photo{
	&:after{
		width: $space*1.5;
		height: $space*1.5;
		position: absolute;
		top: $space;
		right: $space;
		background-color: #000;
		background-image: url(../gfx/icons/plus.svg);
		background-position: center;
		background-size: $space*0.75 $space*0.75;
		background-repeat: no-repeat;
	}
}


/**
* Palm state
*/
@include media-query(palm){
	.highlight--double {
		height: $space*12;
	}
}