/*----------------------------------------------------------------------------*\
    TABS
\*----------------------------------------------------------------------------*/

.tabs__item {
    display: inline;
}
.tabs__title {
    display: inline-block;
    color: color(base-med);
    font-size: $beta;
    font-weight: 300;
    text-decoration: none;
    padding-right: $space*2;
    border-bottom: 1px solid color(base-med);
}

.tabs__title.is-active {
    color: color(brand);
    border-bottom: 3px solid color(brand);
}

.tabs__content {
    display: none;
    width: 100%;
    float: left;

    &.is-open{
        display: block;
    }
}
.about-tab__wrapper{
    font-size: 0;
}
.about-tab{
    display: inline-block;
    padding: $space-half $space;
    background: darken( color(subtle), $amount: 5%);
    font-size: $zeta;
    font-weight: 600;

    &:hover{
        cursor: pointer;
        background: darken(color(subtle), $amount: 10%);
    }
    &.active{
        background: color(subtle);
    }
}
.tab__wrapper{
    padding: $space;

    table{
        tr{
            td:last-child{
                padding-left: $space;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .tabs__link { display: block; }
}


.tabs {
    margin: 0;
    font-size: 0;
}

/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}

