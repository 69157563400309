/*----------------------------------------------------------------------------*\
    MENU ACTION BAR
    .menu-action-bar
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.menu-action-bar {
    background-color: #FFF;
    padding: $space/2 0;
    box-shadow:
        0 3px 6px hsla(0, 0, 0%, 0.15),
        0 2px 4px hsla(0, 0, 0%, 0.12);
}

.menu-action-bar__title {
    white-space: nowrap;
    display: inline-block;
    @include font-size(20px);
    font-weight: 700;
    color: color(brand);
}

.menu-action-bar__label {
    display: inline-block;
    @include font-size(16px);
    font-weight: 700;
    margin-right: $space;
}

.menu-action-bar__bottom-holder {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;

    .menu-action-bar {
        padding-right: $space*3.5; /* Trengo widget :( */
        box-shadow:
            0 -3px 6px hsla(0, 0, 0%, 0.15),
            0 -2px 4px hsla(0, 0, 0%, 0.12);
    }
}

@include media-query(desk){
    .menu-action-bar__inner {
        text-align: right;
    }
}

@include media-query(palm){
    .menu-action-bar__label,
    .menu-action-bar__title {
        display: none;
    }
}