/*----------------------------------------------------------------------------*\
    forms
\*----------------------------------------------------------------------------*/

/* --- MESSAGE AFTER SENDING FORM --- */

#complete-message{
    width: 100%;
    text-align: center;
    background: color(brand);
    color: color(blank);
    padding: $space-half 0;
}

/* --- DEFAULT FORM STYLING --- */

form{
    fieldset{
        font-size: 16px;
        .field{
            font-size: $base-font-size;
            padding: 0 $space-half;
            display: block;
        }
    }

    fieldset.optionset{
        display: block;

        input.radio,
        input.checkbox,
        label{
            display: inline-block;
            width: auto;
        }
    }

    ul.optionset {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: $space;
            }
        }

        > li > label {
            @include font-size(16px);
            display: inline-block;
        }
    }

    .field{
        margin-bottom: $space-half;

        label{
            display: block;
            font-weight: 300;
            margin-bottom: $space-half/2;

            span.required {
                color: #ff6347;
                font-weight: 600;
            }
        }

        input:not([type=radio]):not([type=checkbox]),textarea,
        select{
            width: 100%;
            background-color: color(subtle);
            border: 0;
            outline: 0;
            padding: $space-half;
            border-bottom: 2px solid color(base-med);
            border-radius: 0px;

            &:focus{
                background-color: darken(color(subtle), 10);
            }

            &[readonly=readonly],
            &:disabled,
            &[disabled=disabled]{
                background-color: color(subtle);
                border-bottom: 2px solid color(subtle);
            }
        input[type=text]{
            width: 100%;
            background-color: color(blank);
            border: 0;
            outline: 0;
            padding: $space-half;
            border-bottom: 2px solid color(base-med);
            border-radius: 0px;

            &:focus{
                background-color: darken(color(subtle), 10);
            }

            &[readonly=readonly],
            &:disabled,
            &[disabled=disabled]{
                background-color: color(subtle);
                border-bottom: 2px solid color(subtle);
            }
        }
        }

        select{
            -moz-appearance:none;
            -webkit-appearance:none;
            appearance:none;
            padding: $space-half $space*2 $space-half $space-half;

            background-image:
                linear-gradient(45deg, transparent 50%, color(base) 50%),
                linear-gradient(135deg, color(base) 50%, transparent 50%);
            background-position:
                calc(100% - 20px) 50%,
                calc(100% - 15px) 50%;
            background-size:
                5px 5px,
                5px 5px;
            background-repeat: no-repeat;
        }

        textarea{
            height: 150px;
            resize: vertical;
        }
    }
    .Actions{

        input[type="submit"]{
            @extend .button;
            @extend .button--brand-sec;
        }
    }
}

/*--- END DEFAULT FORM STYLING --- */

#inspireForm{
    form{
        .field{
            input:not([type=radio]):not([type=checkbox]), textarea{
                background: color(blank);
            }
            .optionset{
                list-style: none;
                margin: 0;

                .checkbox{
                    display: inline-block;
                    width: auto;
                    margin-right: $space-half/2;
                }
                label{
                    display: inline-block;
                }
            }
        }
    }
}

.form--contact,
#ContactForm{

    fieldset{
        font-size: 0;
        margin: 0 $space-half*-1;
        .field{
            font-size: $base-font-size;
            padding: 0 $space-half;
            display: inline-block;

            &.textarea{
                width: 100%;
            }
            &.optionset{
                width: 100%;
            }
        }

        .field.optionset {
            display: block;
        }
    }

    .field{
        margin-bottom: $space-half;

        label{
            display: block;
            font-weight: 300;
            margin-bottom: $space-half/2;
        }

        ul.optionset {
            > li > label {
                @include font-size(16px);
                display: inline-block;
            }
        }

        input:not([type=radio]):not([type=checkbox]),
        textarea{
            width: 100%;
            background-color: color(subtle);
            border: 0;
            outline: 0;
            padding: $space-half;
            border-bottom: 2px solid color(base-med);

            &:focus{
                background-color: darken(color(subtle), 10);
            }
        }

        textarea{
            height: 150px;
        }
    }
    .Actions{
        text-align: center;
    }
}

.quotationForm{
    fieldset{
        font-size: 0;
        margin: 0 $space-half*-1;
        .field{
            font-size: $base-font-size;
            padding: 0 $space-half;
            display: inline-block;

            &.textarea{
                width: 100%;
            }
            &.optionset{
                width: 100%;
            }
        }

        #ContactSalutation {
            display: block;
        }
    }
    .field{
        margin-bottom: $space-half;

        label{
            display: block;
            font-weight: 300;
            margin-bottom: $space-half/2;
            font-size: $delta;
        }

        ul.optionset {
            > li > label {
                @include font-size(16px);
                display: inline-block;
            }
        }

        input:not([type=radio]):not([type=checkbox]),
        textarea{
            width: 100%;
            background-color: color(blank);
            border: 0;
            outline: 0;
            padding: $space-half;
            border-bottom: 2px solid color(base-med);

            &:focus{
                background-color: darken(color(blank), 10);
            }
        }

        textarea{
            height: 150px;
        }
    }
    .Actions{
        text-align: center;
    }
}

#DirectLeaseForm,
.form--filter{
    @include media-query(desk){
        position: sticky;
        z-index: 999;
        top: 0;
    }
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    background-color: color(brand);
    padding: $space;
    margin-top: -$space*7;
    margin-bottom: $space;
    fieldset {
        margin-bottom: 0;
    }
    .fieldset {
        display: flex;
        flex-wrap: wrap;
        .field {
            flex: 0 1 20%;
            max-width: 20%; // IE
            @include media-query(lap){
                flex-basis: 33%;
                max-width: 33%; // IE
            }
            @include media-query(palm){
                flex-basis: 100%;
                max-width: 100% // IE
            }
            // min-width: 170px;
        }
    }
    // fieldset,
    .Actions{
        font-size: 0;
        margin-bottom: -$space/2;
        .field{
            width: 20%;
            font-size: $base-font-size;
            padding: 0 $space-half;
            display: inline-block;
            @include media-query(lap){
                width: 33%;
            }
            @include media-query(palm){
               width: 100%;
            }
        }
    }
    select{
        display: block;
        width: 100%;
        padding: $space-half $space*1.5 $space-half $space-half;
        appearance: none;
        border-radius: 0;
        background: url(../gfx/icons/arrow-simple.svg) right $space-half center no-repeat;
        background-color: color(subtle);
        border: 0;
        border-bottom: 2px solid color(base-med);
        outline: 0;
        box-shadow: inset 0 0 0 0px color(brand-sec), 0 0px 0px 0 color(base-med);
        transition: box-shadow 100ms $transition;
        &:focus{
            box-shadow: inset 0 0 0 1px color(brand-sec), 0 2px 4px 0 color(base-med);
        }
    }

    select::-ms-expand {
        display: none;
    }

    input[type=submit]{
        @extend .button;
        @extend .button--brand-sec;
        width: 100%;
    }

    .extended-toggle,
    input[type=reset]{
        -webkit-appearance: none;
        background: none;
        border: 0;
        display: inline-block;
        vertical-align: middle;
        color: color(blank);
        &:hover {
            opacity: .8;
        }
    }

    .extended-toggle {
        display: block;
    }
}
/* Styling for user defined forms */
#UserForm_Form{
    .middleColumn{
        box-shadow: none;
        input[type=text],input[type=email],textarea{
            border: 0;
            padding: $space-half;
            background: color(subtle);
            border-bottom: 2px solid color(base-med);
            outline: none;
            width: 60%;
            margin-bottom: $space-half;
            max-width: 60%;

            &:focus{
                background-color: darken(color(subtle), 10);
            }
        }
        label{
            font-weight: 300;
            font-size: $delta;
            display: inline-block;
            vertical-align: middle;
        }
        input[type=radio],input[type=checkbox]{
            margin-right: $space-half/2;
            display: inline-block;
        }

    }
    nav.Actions{
        input[type=submit]{
            font-weight: 600;
            font-size: $milli;
            width: 50%;
            height: auto !important;
            border: 0;
            padding: $space-half $space;
            background: color(brand-sec);
            color: color(blank);
            box-shadow: 0 3px 0 0px saturate(darken(color(brand-sec), 20), 30);


                &:hover{
                    background-color: saturate(darken(color(brand-sec), 5), 15);
                    box-shadow: 0 2px 0 0px saturate(darken(color(brand-sec), 25), 40);
                    transform: translateY(1px);
                }
        }
    }
}
/*----------------------------------------------------------------------------*\

\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}

