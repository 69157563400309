.accordeon{
	border-bottom: 1px solid color(subtle);
	&:last-child{
		border-bottom: 0px solid color(subtle);
	}
}

.accordeon__btn{
	background-color: #FFF;
	font-weight: 700;
	@include font-size($gamma);
	padding: $space;
	position: relative;
}

.accordeon__icon{
	width: $space;
	height: $space;
	fill: color(base);
	display: none;
	position: absolute;
	top: 50%;
	right: $space;
	transform: translateY(-50%);
}

.accordeon__btn[data-state="passive"] .accordeon__icon--open{
	display: none;
}

.accordeon__btn[data-state="active"] .accordeon__icon--close{
	display: block;
}

.accordeon__panel{
	background-color: #FFF;
	padding: 0 $space $space;
	display: none;
}
@include media-query(palm){
	.accordeon__btn{
		padding: $space $space-half;
		@include font-size($delta);
	}

	.accordeon__panel{
		padding: 0 $space-half $space-half;
	}
}