/*----------------------------------------------------------------------------*\
    VIVITION MODULE
    #vivition-wrapper
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.layer--vivition-overlap {
    z-index: 5;
}

body.is--vivition-fullscreen {
    .layer--vivition-overlap {
        z-index: 0;
    }
}

#vivition-wrapper{
    position: relative;
    overflow-x: hidden;

    .vivition-player{
        height: 60vh;
        min-height: 460px;
        position: relative;
        transition: height .3s ease-in-out;
    }
}

.vivition-wrapper__player{
    text-align: center;
    background-color: #e8eaef;

    position: relative;

    &:before{
        content: '';
        display: block;
        pointer-events: none;
        width: 100%;
        // max-width: 1236px;
        height: $space*6;
        background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
        z-index: 4;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .vivition-player{
        display: block;
        margin: 0 auto;
        width: 100%;
    }
}

.is--vivition-fullscreen .vivition-wrapper__player .vivition-player{
    max-width: none;
}

body.is--vivition-fullscreen {

    .layer.hexon-banner{
        z-index: 9999;
    }

    .vivition-wrapper__player::before{
        z-index: 99991;
        max-width: none;
    }

    .vivition-wrapper__scroll-down{
        z-index: 99992;
    }

    .vivition-wrapper__controls{
        z-index: 99992;
    }

    .vivition-wrapper__thumbbar{
        z-index: 5;
    }

    #vivition-wrapper .vivition-player{
        height: calc(100vh - 150px);
        z-index: 99990;
    }
}

.vivition-wrapper__loader{
    display: none !important;
    opacity: 0;
    visibility: hidden;
}

.vivition-wrapper__scroll-down{
    position: absolute;
    bottom: calc(150px + #{$space/2});
    width: $space*2;
    height: $space*2;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    z-index: 1;
    cursor: pointer;

    .icon{
        fill: #FFFFFF;
        width: $space*2;
        height: $space*2;
    }
}

.vivition-wrapper__controls{
    position: absolute;
    top: $space;
    width: 100%;
    z-index: 5;
    pointer-events: none;
    font-size: 0;

    .site-width{
        padding: 0 $space/2 0 $space;
    }
}

.vivition-wrapper__controls-item{
    display: inline-block;
    vertical-align: top;
    pointer-events: all;
    cursor: pointer;
    width: $space*4;
    background-color: transparent;
    border: 2px solid transparent;
    margin-right: $space/4;
    transition:
        background-color .3s ease-in-out,
        border-color .3s ease-in-out;
    padding: $space/3 $space/4 $space/4;
    text-align: center;

    &:last-child{
        margin-right: 0;
    }
}

.vivition-wrapper__controls-item--extra-space-right{
    margin-right: $space;
}

.vivition-wrapper__controls-label{
    @include font-size($epsilon);
    color: #FFF;

    transition:
        color .3s ease-in-out;
}

.vivition-wrapper__controls-icon{
    text-align: center;
    margin-bottom: $space/4;

    .icon{
        display: inline-block;
        width: $space*1.25;
        height: $space*1.25;
        fill: #FFF;
        transform: scale(0.95);

        transition:
            fill .3s ease-in-out,
            transform .3s ease-in-out;
    }
}

.vivition-wrapper__controls-item--alt{
    width: $space*1.5;
    height: $space*1.5;
    border-radius: 50%;
    border: 2px solid #FFF;
    padding: 0;
}

.js--vivition-close-fullscreen{
    display: none;
}

.is--vivition-fullscreen .js--vivition-close-fullscreen{
    display: inline-block;
}

.vivition-wrapper__controls-item:hover{
    border-color: #FFF;

    .vivition-wrapper__controls-icon .icon{
        transform: scale(1);
    }
}

.vivition-wrapper__controls-item--alt:hover{
    background-color: #FFF;

    .vivition-wrapper__controls-icon .icon{
        fill: color(brand);
        transform: scale(0.95);
    }
}

.vivition-wrapper__controls-item.is--active{
    background-color: #FFF;
    border-color: #FFF;

    .vivition-wrapper__controls-icon .icon{
        fill: color(brand);
    }

    .vivition-wrapper__controls-label{
        color: color(brand);
    }
}

.text--right .vivition-wrapper__controls-item{
    margin-right: 0;
    margin-left: $space/4;
    display: none;

    &.is--enabled{
        display: inline-block;
    }

    &:last-child{
        margin-left: 0;
    }
}

.vivition-wrapper__inner{
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 5;
    pointer-events: none;

    display: none;

    &.is--visible{
        display: block;
    }
}

.vivition-wrapper__thumbbar{
    width: 100%;
    position: relative;

    &::before,
    &::after{
        content: '';
        display: block;
        position: absolute;
        width: $space*5;
        height: 100%;
        top: 0px;
        z-index: 4;
        pointer-events: none;
    }

    &::before{
        left: 0;
        background: linear-gradient(to right, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
    }

    &::after{
        right: 0;
        background: linear-gradient(to left, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
    }

    .slider__arrow {
        z-index: 5;
        cursor: pointer;
    }
}

/*  Responsive
\*----------------------------------------------------------------------------*/

//@include media-query(desk){}
@include media-query(lap){
#vivition-wrapper{
    .vivition-player{
        height: 400px;
    }
}
}

@media screen and (min-width: 481px) and (max-width: 767px){
body.is--vivition-fullscreen {
    #vivition-wrapper .vivition-player{
        height: 100vh;
    }

    .vivition-wrapper__thumbbar-slider{
        display: none;
    }

    .vivition-wrapper__scroll-down{
        bottom: $space/2;
    }
}

#vivition-wrapper{
    .vivition-player{
        height: 350px;
    }
}

.vivition-wrapper__scroll-down{
    bottom: calc(100px + #{$space/2});
}

.vivition-wrapper__controls-item{
    width: $space*2;
}

.vivition-wrapper__controls-item--alt{
    width: $space*1.25;
    height: $space*1.25;

    .vivition-wrapper__controls-icon .icon{
        width: $space;
        height: $space;
    }
}

.vivition-wrapper__controls-item--extra-space-right{
    margin-right: $space/6;
}

.vivition-wrapper__controls-label{
    display: none;
}

.vivition-wrapper__thumbbar-slider{
    img{
        height: 100px;
        width: auto;
    }
}
}

@include media-query(palm){
body.is--vivition-fullscreen {
    #vivition-wrapper .vivition-player{
        height: calc(100vh - 100px);
    }
}

.vivition-wrapper__scroll-down{
    bottom: calc(100px + #{$space/2});
}

#vivition-wrapper{
    .vivition-player{
        height: 350px;
    }
}
.vivition-wrapper__controls-item{
    width: $space*2;
}

.vivition-wrapper__controls-item--alt{
    width: $space*1.25;
    height: $space*1.25;

    .vivition-wrapper__controls-icon .icon{
        width: $space;
        height: $space;
    }
}

.vivition-wrapper__controls-item--extra-space-right{
    margin-right: $space/6;
}

.vivition-wrapper__controls-label{
    display: none;
}

.vivition-wrapper__thumbbar-slider{
    img{
        height: 100px;
        width: auto;
    }
}
}

@media screen and (min-width: 1261px) and (max-height: 650px){
body.is--vivition-fullscreen {
    #vivition-wrapper .vivition-player{
        height: 100vh;
    }

    .vivition-wrapper__thumbbar-slider{
        display: none;
    }

    .vivition-wrapper__scroll-down{
        bottom: $space/2;
    }
}
}

.vivition-wrapper__thumbbar-slider a {
    display: inline-block;
}

.fancybox__container .fancybox__content {
    width: 1920px;
    height: 1440px;
}