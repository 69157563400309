/*----------------------------------------------------------------------------*\
    panelnav
\*----------------------------------------------------------------------------*/
.panel__divider{
    outline: 0;
    border: 0;
    height: 1px;
    display: block;
    background: color(base-med);
    margin-bottom: 0;
}
.panelnav{
    display: block;
    padding: 0;
    margin: 0;
    margin-bottom: $space-half;
}
.panelnav__item{
    position: relative;
    display: block;

    .panelnav__link{
        text-decoration: none;
        font-size: $gamma;
        font-weight: 600;
        color: color(base);
        display: block;
        padding: $space-half/2 0;
    }
}
.panelnavsub__toggler{
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: $space*1.5;
    width: $space*1.5;
    padding: 6px 10px;
    background: color(subtle);
    transform: translateY(-50%);

    svg{
        width: 16px;
        height: 10px;
    }
}
.panelnavsub {
    //pointer-events: none;
    position: fixed;
    z-index: 3;
    left: 200%;
    top: 0;
    width: 100%;
    height: 100%;
    transition: left 0.3s $transition;
    background: color(blank);
    padding: $space;

    &.is--active {
        left: 0;
    }
    &:hover{
        cursor: pointer;
    }
}
.panelnavsub__back{
    background: color(brand-sec);
    outline: 0;
    white-space: nowrap;
    padding: 6px 10px;
    border: 0;
    margin: 0 10px 0 0;

    svg{
        width: 16px;
        height: 10px;
        transform: rotate(180deg);
        margin-top: 6px;
        fill: color(blank);
    }
}

/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

/* Media Queries
   The default media queries shipped with Blocss:
   Uncomment the ones you need
\*------------------------------------------------------------------------*/

/**
 * Desk-wide state
 */
//@include media-query(gt-desk){}

/**
 * Desk state
 */
//@include media-query(desk){}

/**
 * Portable state
 */
//@include media-query(lt-desk){}

/**
 * Lap state
 */
//@include media-query(lap){}

/**
 * Lapup state
 */
//@include media-query(gt-palm){}

/**
 * Palm state
 */
//@include media-query(palm){}

