/*----------------------------------------------------------------------------*\
    $FONT
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.pagetitle{
    font-size: $kilo;
    color: color(base);
    font-weight: 700;
}

.subtitle{
    color: color(base);
    font-weight: 300;
    font-size: $beta;
    margin-bottom: $space;
}

.lede{
    padding: $space;
    border-bottom: 2px solid color(base);
    background-color: color(blank);
}

.content{
    font-weight: 300;
    h2{
        font-weight: 600;
        color: color(base);
        margin-bottom: 0;
    }
    h3{
        font-weight: 600;
        color: color(base);
    }
    p{
        margin-bottom: $space;
    }
    a{
        color: color(brand);
    }
    img{
        max-width: 100%;
    }
}
.ul--clean{
    margin: 0;
}

/* Special color changes */
.alpha.color--brand {
    color: color(base)!important;
}

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .alpha {
                @include font-size(22px);
            }
        }





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/