/*----------------------------------------------------------------------------*\
    Tiles
    .tiles
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.tiles {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
}

.tiles__column {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.tiles__item {
    width: 100%;
    display: flex;
    flex-grow: 1;
    background-size: cover;
    background-color: #000000;
    position: relative;
    text-decoration: none;
}

.tiles__wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
}

.tiles__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: top;
    background-size: cover;
    z-index: 0;
    opacity: 1;
    transform: scale(1);

    transition:
        opacity .2s ease-out,
        transform .2s ease-out;
}

.tiles__item:hover .tiles__background {
    opacity: 0.85;
    transform: scale(1.05);
}

.tiles__label {
    position: absolute;
    right: -$space;
    top: $space*2;
    z-index: 2;
    @include font-size(18px);
    color: #FFF;
    background-color: color(brand);
    padding: $space;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
}

.tiles__content {
    position: relative;
    pointer-events: none;
    flex-direction: column;
    display: flex;
    width: 100%;
    z-index: 1;
    color: #FFFFFF;
    pointer-events: all;

    *:last-child {
        margin-bottom: 0px !important;
    }
}

.tiles__title {
    font-family: $face-base;
    font-weight: 700;
    @include font-size(28px, 0.75);
    color: #FFFFFF;
}

.tiles__item--content {
    background-color: color(subtle);

    .tiles__content,
    .tiles__title {
        color: inherit;
    }
}

.tiles__title--large {
    @include font-size(48px, 1.25);
}

.tiles__description {
    @include font-size(18px);
    margin-bottom: $space;
}

.tiles__intro {
    @include font-size(18px);
    color: color(brand);
}

.tiles__arrow {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;

    display: inline-block;
    width: $space*2;
    height: $space*2;
    background-color: color(brand);
    border-radius: 0%;
    pointer-events: all;

    transform: scale(1);
    transform-origin: 100% 100%;

    transition:
        transform .2s ease-out;

    .icon {
        display: block;
        width: $space*2;
        height: $space*2;
        fill: #FFFFFF;
    }
}

.tiles__arrow:hover {
    transform: scale(1.05);
}

.tiles__column--primary {
    width: 62.5%;
    margin: 2px 4px 2px 2px;

    .tiles__wrapper {
        padding: $space*4;
    }

    .tiles__wrapper--narrow {
        padding: $space;
    }

    .tiles__content {
        align-self: center;
    }

    .tiles__content--bottom-right {
        align-self: flex-end;
        text-align: right;
    }

    .tiles__arrow {
        width: $space*2.5;
        height: $space*2.5;

        .icon {
            width: $space*2.5;
            height: $space*2.5;
        }
    }
}

.tiles__column--secondary {
    height: 624px;
    width: 37.5%;

    .tiles__wrapper {
        padding: $space;
    }

    .tiles__wrapper--wide {
        padding: $space*2;
    }

    .tiles__item {
        margin: 2px 0;
    }

    .tiles__content {
        align-self: flex-end;
        padding-right: $space*2;
    }

    .tiles__item--content .tiles__content {
        align-self: flex-start;
        padding-right: 0px;
    }
}

/*  MEDIA QUERIES
\*----------------------------------------------------------------------------*/

@include media-query(desk){
    .tiles {
        position: relative;
        top: -2px;
        margin-bottom: -4px;
    }
}

@media screen and (min-width: 741px) and (max-width: 960px) {
    .tiles {
        flex-wrap: wrap;
    }

    .tiles__column {
        flex-wrap: nowrap;
        flex-direction: row;
    }

    .tiles__column--primary {
        height: 456px;
        width: 100%;
        margin: 0px 2px;

        .tiles__wrapper {
            padding: $space;
        }
    }

    .tiles__column--secondary {
        width: 100%;

        .tiles__item {
            margin: 4px 2px;
            min-height: 240px;
        }
    }

    .tiles__label {
        right: -$space/2;
    }
}

@media screen and (max-width: 740px) {
    .tiles {
        flex-wrap: wrap;
    }

    .tiles__column {
        flex-wrap: wrap;
    }

    .tiles__title--large {
        @include font-size(28px, 0.75);
    }

    .tiles__column--primary {
        height: auto;
        min-height: 312px;
        width: 100%;
        margin: 0px 0px 2px 0px;

        .tiles__wrapper {
            padding: $space;
        }
    }

    .tiles__column--secondary {
        height: auto;
        width: 100%;

        .tiles__item {
            margin: 2px 0px;
            min-height: 185px;
        }
    }

    .tiles__label {
        right: -$space/2;
    }
}

@include media-query(gt-palm){
    .tiles__item {
        flex-basis: 0;
    }

    .tiles__countdown {
        position: absolute;
        left: 50%;
        bottom: 60px;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
        z-index: 1;
    }
}

@include media-query(lap){
    .tiles__countdown {
        bottom: 24px;

        .countdown__title {
            display: none;
        }
    }
}

@include media-query(palm){
    .tiles__label {
        display: none;
    }

    .tiles__title {
        @include font-size(22px);
    }

    .tiles__column--secondary {
        .tiles__wrapper,
        .tiles__wrapper--wide {
            padding: $space/2;
        }
    }

    .tiles__countdown {
        position: absolute;
        left: 50%;
        bottom: 12px;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;

        .countdown__title {
            display: none;
        }
    }
}
