/*----------------------------------------------------------------------------*\
    usp
\*----------------------------------------------------------------------------*/

.usp{
    text-align: center;
    color: color(brand-sec);
    padding: $space;

    @include media-query(lap){
        padding: 0;
    }

    @include media-query(palm){
        padding: 0;
    }

    .usp__icon{
        height: 64px;
        margin-bottom: $space;

        svg{
            max-height: 64px;
            max-width: 103px;
        }
    }

    .usp__text{
        font-weight: 300;
        font-size: $beta;
        line-height: 1em;

        @include media-query(lap){
            @include font-size(18px);
        }

        @include media-query(palm){
            @include font-size(16px);
        }
    }
}

/*----------------------------------------------------------------------------*\

\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){}

