/*----------------------------------------------------------------------------*\
    footernav
\*----------------------------------------------------------------------------*/

.footernav__title{
    color: color(base);
    margin-bottom: $space-half;
}

.footernav{
    margin: 0;
    padding: 0;
    list-style-type: none;

    .footernav__link{
        display: block;
        color: color(base-med);
        font-weight: 300;
        text-decoration: none;
        transition: color 200ms $transition;

        &:hover{
            color: color(base);
        }
    }
}
.footernav__social-icons{
    img{
        height: 32px;
        width: 32px;
    }
}

.footersubnav{
    margin: 0;
    list-style-type:  none;
    li{
        display: inline-block;
        a{
            display: inline-block;
            color: color(base-med);
            text-decoration: none;
            padding: 0 $space-half;
        }
    }
}

.footernav--extra{
    a{
        display: inline-block;
        color: color(base-med);
        font-weight: 300;
        text-decoration: none;
        transition: color 200ms $transition;

        &:hover{
            color: color(base);
        }
    }
}

/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        @include media-query(lap){
            .footernav__social-icons{
                img{
                    height: 24px;
                    width: 24px;
                }
            }
        }


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .footernav__social-icons{
                img{
                    height: 24px;
                    width: 24px;
                }
            }
        }

