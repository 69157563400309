@keyframes spinner {
	to {transform: rotate(360deg);}
}

.dynamic-gallery{
	height: 485px;
	position: relative;

	.dynamic-gallery__loader{
		z-index: 4;
		position: absolute;
		top: 0;
		left: 0;
		height: calc(100% - 150px);
		width: 100%;
		background-color: color(subtle);

		.loader{
			display: block;
			background-color: transparent;

			.loader__spinner{
				top: 50%;
				margin-top: -15px;
			}
		}
	}
}

.dynamic-gallery.is--vivition{
	height: calc(60vh + 150px);
	min-height: 610px;
	margin-bottom: $space;
}