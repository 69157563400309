$rrssb-txt: #fff !default;
$rrssb-email: #0a88ff !default;
$rrssb-facebook: #306199 !default;
$rrssb-tumblr: #32506d !default;
$rrssb-linkedin: #007bb6 !default;
$rrssb-twitter: #000 !default;
$rrssb-googleplus: #e93f2e !default;
$rrssb-reddit: #8bbbe3 !default;
$rrssb-youtube: #df1c31 !default;
$rrssb-pinterest: #b81621 !default;
$rrssb-pocket: #ed4054 !default;
$rrssb-github: #444 !default;
$rrssb-instagram: #125688 !default;
$rrssb-hackernews: #ff6600 !default;
$rrssb-delicious: #0b79e5 !default;
$rrssb-vk: #4d71a9 !default;
$rrssb-print: #8d98a2 !default;
$rrssb-whatsapp: #43d854 !default;
$rrssb-xing: #005A5F !default;

$rrssb-border-radius: 2px !default;
$rrssb-main-font: "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$social-list: (rrssb-email $rrssb-email, rrssb-facebook $rrssb-facebook, rrssb-tumblr $rrssb-tumblr, rrssb-linkedin $rrssb-linkedin, rrssb-twitter $rrssb-twitter, rrssb-googleplus $rrssb-googleplus, rrssb-youtube $rrssb-youtube, rrssb-reddit $rrssb-reddit, rrssb-pinterest $rrssb-pinterest, rrssb-pocket $rrssb-pocket, rrssb-github $rrssb-github, rrssb-instagram $rrssb-instagram, rrssb-delicious $rrssb-delicious, rrssb-vk $rrssb-vk, rrssb-hackernews $rrssb-hackernews, rrssb-whatsapp $rrssb-whatsapp, rrssb-print $rrssb-print, rrssb-xing $rrssb-xing);

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.rrssb-buttons {
    font-family: $rrssb-main-font;
    font-size: 12px;

    display: flex;
    justify-content: start;
    flex-direction: row;
    gap: 4px;

    animation-duration: .3s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-name: fade-in;
    animation-delay: .2s;

    a {
        height: 36px;
        min-width: 38px;
        padding: 0 8px;

        background-color: #ccc;
        border-radius: $rrssb-border-radius;
        font-weight: bold;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        position: relative;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color 0.2s ease-in-out;

        @each $s-name in $social-list {
            &.#{nth($s-name, 1)} {
                background-color: nth($s-name, 2);

                &:hover {
                    background-color: darken(nth($s-name, 2), 10%);
                }
            }
        }

        .rrssb-icon {
            display: block;
            height: 17px;
            width: 17px;

            svg {
                height: 17px;
                width: 17px;

                path, circle {
                    fill: $rrssb-txt;
                }
            }
        }

        .rrssb-text {
            color: $rrssb-txt;
        }

        &:active {
            box-shadow: inset 1px 3px 15px 0 rgba(22, 0, 0, .25);
        }
    }
}
