.multiple_glideslider {
  margin: 0 auto;

  &__container {
    width: 100%;
    max-width: 1284px;
    margin: 0 auto;
  }

  &__controls {
    position: absolute;
    top: 172px;
    left: -84px;
    width: calc(100% + 168px);
    max-width: 1368px;
  }

  .glide {
    width: calc(100% - 216px); // width: 1116px;
    max-width: 1116px;
    margin: 0 auto;
    position: relative;

    &__track {
      overflow: hidden;
    }

    &__slides {
      display: flex;
      overflow: hidden;

      .card {
        height: 350px;

        .card__title {
          height: 72px;
          overflow: hidden;
          margin-bottom: 6px;
        }
      }
    }
  }
}


